import Rails from '@rails/ujs';
import { each } from 'lodash';
import validate from 'validate.js/validate.js';

const contactForm = document.getElementById('contact_form');
const contactFormInputs = document.querySelectorAll("input:not([type='hidden']), textarea, select")
const ContactFormConstraints = {
  'contact[email]': {
    presence: true,
    email: true
  },
  'contact[name]': {
    presence: true
  },
  'contact[content]': {
    presence: true,
    length: {
      minimum: 20,
      maximum: 500,
      tooShort: "^Minimum of 20 characters required",
      tooLong: '^Maximum of 500 characters allowed'
    }
  },
};

validate.validators.presence.options = { message: "^Can't be blank" };
validate.validators.email.options = { message: "^Is invalid" };

const resetFormGroup = formGroup => {
  formGroup.classList.remove("has-error");
  formGroup.classList.remove("has-success");

  _.each(formGroup.querySelectorAll(".help-block.error"), function (el) {
    el.parentNode.removeChild(el);
  });
}

const addError = (messages, error) => {
  let block = document.createElement("p");
  block.classList.add("help-block");
  block.classList.add("error");
  block.innerText = error;
  messages.appendChild(block);
}

const showErrorsForInput = (input, errors) => {
  let formGroup = input.closest(".formGroup");
  let messages = formGroup.querySelector(".messages");

  resetFormGroup(formGroup);

  if (errors) {
    formGroup.classList.add("has-error");

    _.each(errors, function (error) {
      addError(messages, error);
    });
  } else {
    formGroup.classList.add("has-success");
  }
}

const showErrors = (form, errors) => {
  _.each(form.querySelectorAll("input[name]:not([data-ignored]), select[name]:not([data-ignored]), textarea[name]:not([data-ignored])"), function (input) {
    showErrorsForInput(input, errors && errors[input.name]);
  });
}

const handleFormSubmit = form => {
  let errors = validate(form, ContactFormConstraints);

  if (errors) {
    showErrors(form, errors || {});
  } else {
    Rails.ajax({
      url: form.action,
      type: "POST",
      dataType: "script",
      data: new FormData(form)
    })
  }
}

const init = () => {
  contactForm.addEventListener("submit", function (e) {
    e.preventDefault();
    handleFormSubmit(this);
  });

  for (let i = 0; i < contactFormInputs.length; i++) {
    contactFormInputs.item(i).addEventListener("blur", function () {
      let errors = validate(contactForm, ContactFormConstraints) || {};
      showErrorsForInput(this, errors[this.name])
    });
  }

  contactForm.querySelector('[name="authenticity_token"]').setAttribute('data-ignored', '');
}

init();
