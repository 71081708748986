import anime from 'animejs/lib/anime.js';

const sections = Array.from(document.querySelectorAll('section[id]')).concat([document.querySelector("#contact")]);
const links = Array.from(document.querySelectorAll('.navItem, #contact-anchor'));
const header = document.getElementById("header");
const logoImage = document.getElementById("logo-image");
const navMenuDiv = document.getElementById("nav-content");
const navMenu = document.getElementById("nav-toggle");

let scrollpos = window.scrollY;

const scrollToSection = (event, i) => {
  event.preventDefault();

  const { top } = sections[i].getBoundingClientRect();
  const scrollCoords = {
    y: top + window.pageYOffset
  }

  anime({
    targets: scrollCoords,
    duration: 250,
    easing: 'easeInOutCubic',
    update: () => window.scroll({ top: scrollCoords.y, left: 0, behavior: 'smooth' })
  })
}

const check = (event) => {
  let target = (event && event.target);

  if (!checkParent(target, navMenuDiv)) {
    if (checkParent(target, navMenu)) {
      if (navMenuDiv.classList.contains("hidden")) {
        navMenuDiv.classList.remove("hidden");
      } else { navMenuDiv.classList.add("hidden"); }
    } else {
      navMenuDiv.classList.add("hidden");
    }
  }
}

const checkParent = (target, el) => {
  while (target.parentNode) {
    if (target == el) { return true; }
    target = target.parentNode;
  }
  return false;
}

const init = () => {
  links.forEach((link, i) => link.addEventListener('click', (e) => scrollToSection(e, i)))

  document.addEventListener('scroll', function () {
    scrollpos = window.scrollY;

    if (scrollpos > 10) {
      header.classList.add("bg-white", "shadow");
      logoImage.classList.remove("filter-invert");
      navMenuDiv.classList.remove("bg-gray-100", "lg:text-white");
      navMenuDiv.classList.add("bg-white", "lg:text-gray-800");
    }
    else {
      header.classList.remove("bg-white", "shadow");
      logoImage.classList.add("filter-invert");
      navMenuDiv.classList.remove("bg-white", "lg:text-gray-800");
      navMenuDiv.classList.add("bg-gray-100", "lg:text-white");
    }
  });

  document.addEventListener('click', check)
}

init();
